// devices
export const GET_SCAN_DEVICES = "GET_SCAN_DEVICES"
export const GET_SCAN_DEVICES_SUCCESS = "GET_SCAN_DEVICES_SUCCESS"
export const GET_SCAN_DEVICES_FAIL = "GET_SCAN_DEVICES_FAIL"

export const ADD_SCAN_DEVICE = "ADD_SCAN_DEVICE"
export const ADD_SCAN_DEVICE_SUCCESS = "ADD_SCAN_DEVICE_SUCCESS"
export const ADD_SCAN_DEVICE_FAIL = "ADD_SCAN_DEVICE_FAIL"

export const UPDATE_SCAN_DEVICE = "UPDATE_SCAN_DEVICE"
export const UPDATE_SCAN_DEVICE_SUCCESS = "UPDATE_SCAN_DEVICE_SUCCESS"
export const UPDATE_SCAN_DEVICE_FAIL = "UPDATE_SCAN_DEVICE_FAIL"

export const GET_LICENSE_SCAN_DEVICE = "GET_LICENSE_SCAN_DEVICE"
export const GET_LICENSE_SCAN_DEVICE_SUCCESS = "GET_LICENSE_SCAN_DEVICE_SUCCESS"
export const GET_LICENSE_SCAN_DEVICE_FAIL = "GET_LICENSE_SCAN_DEVICE_FAIL"

export const DELETE_SCAN_DEVICE = "DELETE_SCAN_DEVICE"
export const DELETE_SCAN_DEVICE_SUCCESS = "DELETE_SCAN_DEVICE_SUCCESS"
export const DELETE_SCAN_DEVICE_FAIL = "DELETE_SCAN_DEVICE_FAIL" 

// cards
export const GET_CARDS = "GET_CARDS"
export const GET_CARDS_SUCCESS = "GET_CARDS_SUCCESS"
export const GET_CARDS_FAIL = "GET_CARDS_FAIL"

export const ADD_CARD = "ADD_CARD"
export const ADD_CARD_SUCCESS = "ADD_CARD_SUCCESS"
export const ADD_CARD_FAIL = "ADD_CARD_FAIL"

export const DELETE_CARD = "DELETE_CARD"
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS"
export const DELETE_CARD_FAIL = "DELETE_CARD_FAIL" 

export const GET_WAITING_LIST_CARD = "GET_WAITING_LIST_CARD"
export const GET_WAITING_LIST_CARD_SUCCESS = "GET_WAITING_LIST_CARD_SUCCESS"
export const GET_WAITING_LIST_CARD_FAIL = "GET_WAITING_LIST_CARD_FAIL"

export const ADD_WAITING_LIST_CARD = "ADD_WAITING_LIST_CARD"
export const ADD_WAITING_LIST_CARD_SUCCESS = "ADD_WAITING_LIST_CARD_SUCCESS"
export const ADD_WAITING_LIST_CARD_FAIL = "ADD_WAITING_LIST_CARD_FAIL"

export const DELETE_WAITING_LIST_CARD = "DELETE_WAITING_LIST_CARD"
export const DELETE_WAITING_LIST_CARD_SUCCESS = "DELETE_WAITING_LIST_CARD_SUCCESS"
export const DELETE_WAITING_LIST_CARD_FAIL = "DELETE_WAITING_LIST_CARD_FAIL"
