// admin
export const GET_STAFFS = "GET_STAFFS"
export const GET_STAFFS_SUCCESS = "GET_STAFFS_SUCCESS"
export const GET_STAFFS_FAIL = "GET_STAFFS_FAIL"

export const UPDATE_STAFFS = "UPDATE_STAFFS"
export const UPDATE_STAFFS_SUCCESS = "UPDATE_STAFFS_SUCCESS"
export const UPDATE_STAFFS_FAIL = "UPDATE_STAFFS_FAIL"

export const DELETE_STAFFS = "DELETE_STAFFS"
export const DELETE_STAFFS_SUCCESS = "DELETE_STAFFS_SUCCESS"
export const DELETE_STAFFS_FAIL = "DELETE_STAFFS_FAIL"

export const NEW_STAFF = "NEW_STAFF"
export const NEW_STAFF_SUCCESS = "NEW_STAFF_SUCCESS"
export const NEW_STAFF_FAIL = "NEW_STAFF_FAIL"
export const RESET_STAFF = "RESET_STAFF"
export const RESET_STAFF_SUCCESS = "RESET_STAFF_SUCCESS"