

export const GET_ALL_NOTIFICATION = "GET_ALL_NOTIFICATION"
export const GET_ALL_NOTIFICATION_SUCCESS = "GET_ALL_NOTIFICATION_SUCCESS"
export const GET_ALL_NOTIFICATION_FAIL = "GET_ALL_NOTIFICATION_FAIL"

export const GET_TOTAL_NOTIFICATIONS = "GET_TOTAL_NOTIFICATIONS"
export const GET_TOTAL_NOTIFICATIONS_SUCCESS = "GET_TOTAL_NOTIFICATIONS_SUCCESS"
export const GET_TOTAL_NOTIFICATIONS_FAIL = "GET_TOTAL_NOTIFICATIONS_FAIL"


export const GET_NEWEST_NOTIFICATION = "GET_NEWEST_NOTIFICATION"
export const GET_NEWEST_NOTIFICATION_SUCCESS = "GET_NEWEST_NOTIFICATION_SUCCESS"
export const GET_NEWEST_NOTIFICATION_FAIL = "GET_NEWEST_NOTIFICATION_FAIL"

export const MARK_READ_NOTIFICATION = "MARK_READ_NOTIFICATION"
export const MARK_READ_NOTIFICATION_SUCCESS = "MARK_READ_NOTIFICATION_SUCCESS"
export const MARK_READ_NOTIFICATION_FAIL = "MARK_READ_NOTIFICATION_FAIL"
