// admin
export const GET_TEAMS = "GET_TEAMS"
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS"
export const GET_TEAMS_FAIL = "GET_TEAMS_FAIL"

export const ADD_TEAMS = "ADD_TEAMS"
export const ADD_TEAMS_SUCCESS = "ADD_TEAMS_SUCCESS"
export const ADD_TEAMS_FAIL = "ADD_TEAMS_FAIL"

export const UPDATE_TEAMS = "UPDATE_TEAMS"
export const UPDATE_TEAMS_SUCCESS = "UPDATE_TEAMS_SUCCESS"
export const UPDATE_TEAMS_FAIL = "UPDATE_TEAMS_FAIL"

export const DELETE_TEAMS = "DELETE_TEAMS"
export const DELETE_TEAMS_SUCCESS = "DELETE_TEAMS_SUCCESS"
export const DELETE_TEAMS_FAIL = "DELETE_TEAMS_FAIL"